import {responsive, checkMedia} from "./modules/responsivity";
import slick from 'slick-carousel';
import lottie from 'lottie-web';
import {TimelineMax} from "gsap/TimelineMax";
import {Elastic} from "gsap";
import ScrollMagic from 'scrollmagic';
import "imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap"
import hammer from 'hammerjs';
import $ from "jquery";

/**
 * Homepage scripts
 */
$(document).ready(function () {

	window.addEventListener("jt-book-submit", function(event) {
		$('.ajax-loader').addClass('active');
		naja.makeRequest('post', '/?do=bookNow',
		{
			...event.detail.reservation
		},
		{
			history: false,
		});
		naja.addEventListener('complete', function () {
			$('.ajax-loader').removeClass('active');
		});

	}, false);


	/**
	 * Lottie animation in the header
	 */
	const container = $('#hp-top');
	const animation = $('#hp-top-anim');
	const id = document.getElementById('hp-top-anim');
	const cityAnimation = lottie.loadAnimation({
		container: id,
		renderer: 'svg',
		loop: true,
		autoplay: true,
		path: '/assets/front/img/hp-anim/data.json'
	});

	/**
	 * Responsivity on the lottie animation
	 * and other responsivity
	 */

	cityAnimation.addEventListener('data_ready', function () {
		responsive(function () {
			let height = animation.innerHeight();
			if (height > 667 && !checkMedia(568, "max")) {
				container.css('height', height + 'px');
			}
		});
	});

	/**
	 * Roller
	 */
	assignCarouselClasses();

	$('#hp-how-it-works .control.right').click(function () {
		moveToSelected('next');
	});

	$('#hp-how-it-works .control.left').click(function () {
		moveToSelected('prev');
	});

	const hammertime = new Hammer(document.getElementById('package-slider'), {});
	hammertime.on('swipeleft', (e) => {
		moveToSelected('next')
	});

	hammertime.on('swiperight', (e) => {
		moveToSelected('prev')
	});

	/**
	 * Reference slider
	 */
	const refSlider = $('#hp-references .slider').slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		variableWidth: false,
		adaptiveHeight: true
	});

	$('#hp-references .control.right').click(function () {
		refSlider.slick('slickNext');
	});

	$('#hp-references .control.left').click(function () {
		refSlider.slick('slickPrev');
	});

	/**
	 * Section about animation
	 */
	const controller = new ScrollMagic.Controller({
		refreshInterval: 100
	});

	/**
	 * Parallax top
	 */
	const topScroll = new ScrollMagic.Scene({
		triggerElement: document.getElementById('homepage'),
		duration: $('#hp-top').innerHeight(),
		offset: $('#hp-top .button').offset().top - 100
	})
		.on('progress', function (e) {
			let shift = -e.progress * 500;
			$('#hp-top .parallax').css('transform', 'translateY(' + shift + 'px)');
		})
		.addTo(controller);

	const circles = $('.circles').children();
	const cards = $('#hp-about').find('.card-s');
	const
		c1 = circles[0],
		c2 = circles[1],
		c3 = circles[2],
		logo = $('#logo-big');

	let tl1 = new TimelineMax();
	tl1.add("start", 0);
	tl1.from(c3, 0.8, {autoAlpha: 0, scale: 0, ease: Elastic.easeOut.config(1, 0.5)}, "start");
	tl1.from(c2, 0.8, {autoAlpha: 0, scale: 0, ease: Elastic.easeOut.config(1, 0.5)}, "-=0.6");
	tl1.from(c1, 0.8, {autoAlpha: 0, scale: 0, ease: Elastic.easeOut.config(1, 0.5)}, "-=0.6");
	tl1.from(logo, 0.8, {autoAlpha: 0, scale: 2, ease: Elastic.easeOut.config(1.5, 0.8)}, "-=0.6");
	tl1.staggerFrom(cards, 0.8, {autoAlpha: 0, scale: 0, ease: Elastic.easeOut.config(1, 0.5)}, 0.1, "-=0.7");

	const settings = {
		triggerElement: document.getElementById('hp-about'),
		offset: 200,
		reverse: false
	};

	const aboutReveal = new ScrollMagic.Scene(settings);
	aboutReveal
		.setTween(tl1)
		.addTo(controller);

	const aboutScroll = new ScrollMagic.Scene(settings)
		.duration($('#hp-about').innerHeight() + 500)
		.reverse(true)
		.offset(-500)
		.on('progress', function (e) {
			let shift = 200 + (e.progress * 200);
			$('.bullet').css('transform', 'translateY(' + -shift + 'px)');
		})
		.addTo(controller);

	if (!checkMedia(900)) {
		const pragueScroll = new ScrollMagic.Scene({
			triggerElement: document.getElementById('hp-references'),
			duration: $('#hp-references').innerHeight()
		})
			.on('progress', function (e) {
				let frontDelay = 0;
				let backDelay = 0;
				if (!checkMedia(992)) {
					frontDelay = 450 * e.progress;
					backDelay = 250 * e.progress;
				} else {
					frontDelay = 450 * e.progress;
					backDelay = 350 * e.progress;
				}
				$('.silhouette-back').css('transform', 'translateY(' + backDelay + 'px)');
				$('.silhouette-front').css('transform', 'translateY(' + frontDelay + 'px)');
			})
			.addTo(controller)
	}

});


function moveToSelected(element) {
	let selected = element;

	if (element === "next") {
		selected = $(".selected").next();
	}

	if (element === "prev") {
		selected = $(".selected").prev();
	}

	// Do nekonečna a ještě dál směrem dopředu
	if ($(selected).nextAll().length < 3 && element === "next") {
		const elements = $('#package-slider .hideLeft');
		const lastElement = elements[0];
		$(lastElement).removeClass("hideLeft").addClass("nextRightSecond");
		$('#package-slider').append(lastElement)
	}

	// Do nekonečna a ještě dál směrem dozadu
	if ($(selected).prevAll().length < 3 && element === "prev") {
		const elements = $('#package-slider .hideRight');
		const lastElement = elements[elements.length - 1];
		$(lastElement).removeClass("hideRight").addClass("prevLeftSecond");
		$('#package-slider').prepend(lastElement)
	}

	let next = $(selected).next();
	let prev = $(selected).prev();
	let prevSecond = $(prev).prev();
	let nextSecond = $(next).next();

	$(selected).removeClass().addClass("selected");

	$(prev).removeClass().addClass("prev");
	$(next).removeClass().addClass("next");

	$(nextSecond).removeClass().addClass("nextRightSecond");
	$(prevSecond).removeClass().addClass("prevLeftSecond");

	$(nextSecond).nextAll().removeClass().addClass('hideRight');
	$(prevSecond).prevAll().removeClass().addClass('hideLeft');

}

function assignCarouselClasses() {
	const elements = $('#package-slider > div');
	const size = elements.length;
	const middle = parseInt(size / 2);

	if (size === 3) {

		$(elements[middle - 1]).addClass('prev');
		$(elements[middle]).addClass('selected');
		$(elements[middle + 1]).addClass('next');

	}

	if (size >= 5) {

		$(elements[middle - 2]).addClass('prevLeftSecond');
		$(elements[middle - 1]).addClass('prev');
		$(elements[middle]).addClass('selected');
		$(elements[middle + 1]).addClass('next');
		$(elements[middle + 2]).addClass('nextRightSecond');

		for (let i = middle - 3; i >= 0; i--) {
			$(elements[i]).addClass('hideLeft')
		}

		for (let i = middle + 3; i < size; i++) {
			$(elements[i]).addClass('hideRight')
		}
	}
}
